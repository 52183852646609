import React from "react"
import { Link, graphql } from "gatsby"

// import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import { rhythm, scale } from "../utils/typography"
import { structuredVerdict } from "../components/structured"

// import jsdom from "jsdom"

const TitleLink = (props) => (
  <span className="text-red-800 hover:text-blue-500">
    <Link
      to={props.linkto}
      style={{
        boxShadow: `none`,
        textDecoration: `none`,
        color: `inherit`,
      }}
    >{props.label}</Link>
  </span> 
) 

// const sitex = <x>Fantasy<br />Foot<u>ball</u>ers</x>
const siteXform = { FantasyFootballers: <x>Fantasy<br className="xl:hidden" />Footballers</x> ,
  FootballDiehards: <x>Football<br className="md:hidden" />Diehards</x>,
  FulltimeFantasy: <x>Fulltime<br className="md:hidden" />Fantasy</x>,
  FantasySixpack: <x>Fantasy<br className="md:hidden" />Sixpack</x>,
  FantasySharks: <x>Fantasy<br className="md:hidden" />Sharks</x>,
  DraftSharks: <x>Draft<br className="md:hidden" />Sharks</x>,
  TheFFAdvice: <x>TheFF<br className="md:hidden" />Advice</x>,
  TheHuddle: <x>The<br className="md:hidden" />Huddle</x>,
}


const prepareImages = function(fragment, imageobj) { // (imagelist, imageobj) {
  // with browser-native DOMParser you could use tree.images
  let imagelist = (fragment.documentElement == null) ? [] : fragment.documentElement.getElementsByTagName('img')
  Array.from(imagelist).forEach((i) => {
    i.setAttribute('src', imageobj[i.getAttribute('ref')]) // set SRC attribute on IMG elements
  })
}


class VerdictTemplateY extends React.Component {

  render() {

    // post-process our YAML HTML
    // specifically, first step is to edit image tags to use correct src
    const reviewHTML = function(html) {
      return html
      // let domino = require('domino');
      // let fragment = domino.createDocument(html, true) // convert html dtring to a dom document
      // prepareImages(fragment, images);
      // return fragment.body.innerHTML
      /* previously tried to use:
        raw DOMParser - works great in-browser, best API, but not compatible with node/webpack
        jsdom - doesn't work with webpack
        xmldom - buggy, old, serializing didn't work
        domino - browser error on build: `right-hand side of 'instanceof' is not an object`
      */
    }

    // const { previous, next } = this.props.pageContext
    const m = this.props.data.yaml.meta
    const c = this.props.data.yaml.content
    const articleTitle = `The Judge's Verdict: ${m.sport} ${m.season} Week ${m.week}`
    const url = `${m.sport}/${m.season}/${m.sport}-${m.season}-week-${m.week}-${m.slugext}`
    const sv = structuredVerdict(m.week, url, m.headline)
    const meta_desc = (m.description) ? `Reviews of fantasy ${m.sport} expert predictions: ${m.description}` : `Case Summary: ${c.argument}`
    // shortcut for creating an object: {refnumber: src, refnumber: src, etc...}
    const images = (m.images || []).reduce((obj, image) => (obj[image.refnum] = image.src.publicURL, obj), {})
    

    return (
      <Layout>
        <SEO
          title={articleTitle}
          description={meta_desc} // {post.frontmatter.description || post.excerpt}"
          structured={sv}
        />
        <article className="font-typ mt-4 whitespace-pre-line">
          <header className="mb-12">
            <h1 className="text-2xl md:text-3xl text-center">
              <span className="inline-block">The Judge's Verdict:<span> </span></span>
              <br className="md:hidden" /><span> </span>
              <TitleLink linkto={`/verdicts/${m.sport}`} label={m.sport}></TitleLink><span> </span>
              <TitleLink linkto={`/verdicts/${m.sport}/${m.season}`} label={m.season}></TitleLink><span> </span>
              Week {m.week}
            </h1>
            <div className="text-right mr-8 md:mr-48">{m.date}</div>
          </header>
          <section className="mb-12 mx-2 md:mx-auto">
            <div className="text-xl">CASE SUMMARY:</div>
            <div dangerouslySetInnerHTML={{__html: reviewHTML(c.argument)}} />
          </section>
          <section className="mb-12 mx-2 md:mx-auto">
            <div className="text-xl">DEFENDANT TESTIMONY:</div>
            <div className="mt-1 mb-4" dangerouslySetInnerHTML={{__html: reviewHTML(c.expert_pre)}} />
            <div className="table border-collapse md:max-w-90pct mx-auto">

              {c.experts.map(e => {
                return(
                  <div className="table-row border-b border-gray-500" key={e.site}>
                    <div className="table-cell font-bold pr-2 md:pr-6 py-1">{siteXform[e.site] || e.site}</div>
                    <div className="table-cell">{e.ev}</div>
                  </div>
                )
              })}  
            </div>
            <div className="mt-4" dangerouslySetInnerHTML={{__html: reviewHTML(c.expert_post)}} />
            <div className="text-center text-sm mt-6">(read more about the fantasy football experts  <TitleLink linkto={`/help/experts`} label="here"></TitleLink>)</div>
          </section>
          <section className="mb-8 mx-2 md:mx-auto">
            <div className="text-xl">VERDICT ANALYSIS:</div>
            <div dangerouslySetInnerHTML={{__html: reviewHTML(c.analysis)}} />
          </section>
          {/* <section dangerouslySetInnerHTML={{ __html: post.html }} /> */}
          <hr
            // style={{
            //   marginBottom: rhythm(1),
            // }}
          />
          {/* <footer>
          </footer> */}
        </article>
        <div className="mt-8 text-center">See all season point values at the <Link to="/scores">Scoreboard</Link></div>
        {/* <nav>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.week}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.week} →
                </Link>
              )}
            </li>
          </ul>
        </nav> */}
      </Layout>
    )
  }
}

export default VerdictTemplateY

export const pageQuery = graphql`
  query VerdictBySlugYaml($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    yaml(fields: { slug: { eq: $slug } }) {
      id
      meta {
        date(formatString: "MMMM D, YYYY")
        sport
        week
        season
        slugext
        tooltip
        description
        images {
          refnum
          
          src {
            publicURL
            childImageSharp {
              original {
                src
              }
              resolutions(width: 400, height: 400) {
                originalName
              }
            }
          }
        }
      }
      content {
        argument
        expert_pre
        experts {
          site
          ev
        }
        expert_post
        analysis
      }
    }
  }
`
