import React from "react"

export function structuredVerdict(week, urltail, headline) {

  const url = `https://www.fjudge.com/verdicts/${urltail}`

  const schema = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "@id": url,
    "url": url,
    "headline": headline,
    "publisher": {
      "@type" : "Organization",
      "name": "The Fantasy Judge",
      "url" : "http://www.fjudge.com",
      "logo": {
        "@type": "ImageObject",
        "@id": "fjudge-logo",
        "url":  `https://cdn.fjudge.com/logos/FJlogo_week${week}.png`
      },
      "contactPoint": {
        "@type" : "ContactPoint",
        "email": "yourhonor(at)fjudge.com",
        "contactType" : "customer service",
        "areaServed" : ["US","CA"],
        "url" : "https://www.fjudge.com/help/contact"
      }
      
    },
    "datePublished": "2019-10-06",
    "author": "The Fantasy Judge",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": url
    },
    "image": `https://cdn.fjudge.com/logos/FJlogo_week${week}.png`
  }

  return (
  <script type="application/ld+json">
    {JSON.stringify(schema)}
  </script>
  )
}

